<template>
    <div>
        <ts-page-title
            :title="$t('inventoryList.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('inventoryList.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-flex tw-space-x-3">
                        <a-range-picker
                            v-model="dateRange"
                            :allowClear="false"
                            format="DD-MM-YYYY"
                            valueFormat="DD-MM-YYYY"
                            style="width: 200px"
                            @change="fetchData()"
                        />
                        <a-select
                            v-if="!$whois.shop()"
                            show-search
                            v-model="shopId"
                            :placeholder="$t('inventoryList.shopName')"
                            style="width: 200px"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            :filter-option="false"
                            :not-found-content="null"
                            :loading="shop_loading"
                            @search="handleSearch"
                            @change="onCustomerChange"
                        >
                            <a-select-option
                                v-for="s in shopList"
                                :key="s.shop_id"
                            >
                                {{ s.shop_name }}
                            </a-select-option>
                        </a-select>
                        <ts-button @click.prevent="onPdf">
                            <i class="fas fa-file-pdf tw-text-red-500"></i>
                        </ts-button>
                    </div>
                    <a-tooltip
                        placement="topLeft"
                        :title="$t('inventoryList.search')"
                    >
                        <a-input
                            v-model="search"
                            v-tooltip="$t('inventoryList.search')"
                            :placeholder="$t('inventoryList.search')"
                            style="width: 250px"
                            class="inline-block"
                        />
                    </a-tooltip>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td>{{ record.shop_name }}</td>
                        <td>{{ record.barcode }}</td>
                        <td>{{ record.product_name_kh }}</td>
                        <td>{{ record.product_name_en }}</td>
                        <td>{{ record.uom_name_en }}</td>
                        <td class="tw-text-center">
                            {{ record.begining_balance }}
                        </td>
                        <td class="tw-text-center">{{ record.stock_in }}</td>
                        <td class="tw-text-center">
                            <button
                                v-if="record.stock_out > 0"
                                :disabled="record.stock_out == 0"
                                class="tw-bg-blue-500 tw-px-3 tw-rounded tw-text-white"
                                @click.prevent="onShowStockOut(record)"
                            >
                                {{ record.stock_out }}
                            </button>
                            <span v-else>{{ record.stock_out }}</span>
                        </td>

                        <td class="tw-text-center">
                            <button
                                v-if="record.quantity_pending > 0"
                                :disabled="record.quantity_pending == 0"
                                class="tw-bg-blue-500 tw-px-3 tw-rounded tw-text-white"
                                @click.prevent="onShowPending(record)"
                            >
                                {{ record.quantity_pending }}
                            </button>
                            <span v-else>{{ record.quantity_pending }}</span>
                        </td>
                        <td class="tw-text-center">
                            {{ record.stock_return }}
                        </td>
                        <td class="tw-text-center">
                            {{ record.ending_balance }}
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- preview pdf -->
        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
            <ts-preview-pdf v-model="loading" :src="src" />
        </a-modal>

        <a-modal
            :title="$t(`inventoryList.${title}`)"
            width="90%"
            v-model="show_pending"
            centered
            :footer="null"
            :zIndex="1024"
        >
            <div class="tw-w-full tw-overflow-x-auto">
                <ts-table
                    :columns="pendingListcolumns"
                    :records="pendingResources"
                    :loading="loading_pending_list"
                >
                    <template v-slot="{ record }">
                        <td>{{ record.booking_time }}</td>
                        <td>{{ record.booking_number }}</td>
                        <td>{{ record.packaging_date }}</td>
                        <td>{{ record.packaging_number }}</td>
                        <td class="tw-text-center">
                            {{ record.stock_quantity }}
                        </td>
                        <td>{{ record.destination }}</td>
                        <td>{{ record.receiver_phone }}</td>
                        <td>{{ record.delivery_status }}</td>
                        <td>{{ record.status_changed_date }}</td>
                        <td>{{ record.status_remark }}</td>
                    </template>
                </ts-table>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { debounce } from "lodash";
import { mapActions, mapState } from "vuex";
export default {
    name: "stockIn-Index",
    data() {
        return {
            title: "pending",
            errors: new Errors(),
            loading: false,
            show_pending: false,
            loading_pending_list: false,
            shop_loading: false,
            preview_pdf: false,
            shop_id: undefined,
            src: undefined,
            shopList: [],
            pendingResources: []
        };
    },
    computed: {
        ...mapState("inventory/inventoryList", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.inventory.inventoryList.search;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/inventoryList/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "inventory/inventoryList/RESET_CURRENT_PAGE"
                );
            }
        },
        dateRange: {
            get() {
                return this.$store.state.inventory.inventoryList.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/inventoryList/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        shopId: {
            get() {
                return this.$store.state.inventory.inventoryList.shop_id;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/inventoryList/SET_SHOP_ID",
                    newValue
                );
            }
        },
        pendingListcolumns() {
            return [
                { name: this.$t("inventoryList.bookingDate") },
                { name: this.$t("inventoryList.bookingNumber") },
                { name: this.$t("inventoryList.packagingDate") },
                { name: this.$t("inventoryList.packagingNumber") },
                {
                    name: this.$t("inventoryList.quantity"),
                    style: "text-align:center"
                },
                { name: this.$t("inventoryList.destination") },
                { name: this.$t("inventoryList.receiverPhone") },
                { name: this.$t("inventoryList.status") },
                { name: this.$t("inventoryList.changeStatusDate") },
                { name: this.$t("inventoryList.remark") }
            ];
        },
        columns() {
            return [
                {
                    name: this.$t("inventoryList.shopName"),
                    sortKey: "shop_name"
                },
                {
                    name: this.$t("inventoryList.barcode"),
                    sortKey: "barcode"
                },
                {
                    name: this.$t("inventoryList.productNameKh"),
                    sortKey: "product_name_kh"
                },
                {
                    name: this.$t("inventoryList.productNameEN"),
                    sortKey: "product_name_en"
                },
                { name: this.$t("inventoryList.uom"), sortKey: "uom_name_en" },
                {
                    name: this.$t("inventoryList.beginBalance"),
                    style: "text-align:center",
                    sortKey: "begining_balance"
                },
                {
                    name: this.$t("inventoryList.stockIn"),
                    style: "text-align:center",
                    sortKey: "stock_in"
                },
                {
                    name: this.$t("inventoryList.stockOut"),
                    style: "text-align:center",
                    sortKey: "stock_out"
                },
                {
                    name: this.$t("inventoryList.pending"),
                    style: "text-align:center",
                    sortKey: "quantity_pending"
                },
                {
                    name: this.$t("inventoryList.stockReturn"),
                    style: "text-align:center",
                    sortKey: "stock_return"
                },
                {
                    name: this.$t("inventoryList.endingBalance"),
                    style: "text-align:center",
                    sortKey: "ending_balance"
                }
            ];
        }
    },

    methods: {
        ...mapActions("inventory/inventoryList", [
            "getFormViewData",
            "fetchPending",
            "fetchStockOut"
        ]),
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("inventory/inventoryList/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        onPdf() {
            this.loading = true;
            this.preview_pdf = true;
            this.src = undefined;
            this.$store
                .dispatch("inventory/inventoryList/toPdf")
                .then(response => {
                    this.src = response.url;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => (this.loading = false));
        },
        handleSearch: debounce(function(value) {
            this.shop_loading = true;
            this.getFormViewData({
                params: {
                    search: value
                }
            })
                .then(response => {
                    this.shopList = response.data.shop;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.shop_loading = false;
                });
        }, 1000),
        onCustomerChange() {
            this.fetchData();
        },
        onShowPending(record) {
            const { shop_id, product_id, uom_id } = record;
            this.title = "pending";
            this.show_pending = true;
            this.pendingResources = [];
            this.loading_pending_list = true;
            this.fetchPending({
                shop_id,
                product_id,
                uom_id,
                date_to: this.dateRange[1]
            })
                .then(response => {
                    this.pendingResources = response.data;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => (this.loading_pending_list = false));
        },
        onShowStockOut(record) {
            const { shop_id, product_id, uom_id } = record;
            this.title = "stockOut";
            this.show_pending = true;
            this.pendingResources = [];
            this.loading_pending_list = true;
            this.fetchStockOut({
                shop_id,
                product_id,
                uom_id,
                date_from: this.dateRange[0],
                date_to: this.dateRange[1]
            })
                .then(response => {
                    this.pendingResources = response.data;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => (this.loading_pending_list = false));
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("inventory/inventoryList/RESET_STATE");
        next();
    }
};
</script>
